import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function JobApplicationOptions() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  const [casualRole, setCasualRole] = useState(false)
  const [cleaningRole, setCleaningRole] = useState(false)

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold ml-4 mt-4">
          Please select to put a tick on your Job option
        </h1>

        <div className="p-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={user.casual_labour_role}
              onChange={() => {
                setCasualRole(!casualRole)
                saveUser({
                  casual_labour_role: !casualRole,
                })
              }}
              style={{
                transform: "scale(5)",
                marginLeft: "1em",
                marginTop: "2em",
              }}
            />
            <label
              className="form-check-label display-3"
              style={{ marginLeft: "1em" }}
              htmlFor="defaultCheck1"
            >
              Casual Labour Role
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={user.cleaning_specialist_role}
              onChange={() => {
                setCleaningRole(!cleaningRole)
                saveUser({
                  cleaning_specialist_role: !cleaningRole,
                })
              }}
              style={{
                transform: "scale(5)",
                marginLeft: "1em",
                marginTop: "2em",
              }}
            />
            <label
              className="form-check-label display-3"
              style={{ marginLeft: "1em" }}
              htmlFor="defaultCheck1"
            >
              Cleaning Specialist Role
            </label>
          </div>
        </div>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            size="medium"
            text="Next"
            disabled={
              !user.casual_labour_role && !user.cleaning_specialist_role
            }
            onClick={() => {
              saveDraftUser(user)
              navigate("/registration-form-start")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/job-application-start")}
          />
        </div>
      </div>
    </Background>
  )
}
